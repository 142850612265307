import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { draw } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';
import { ToChucVungNuoi } from 'src/app/shared/ToChucVungNuoi.model';
import { ToChucVungNuoiService } from 'src/app/shared/ToChucVungNuoi.service';

import * as maplibregl from 'maplibre-gl';

import * as MapboxDraw from '@mapbox/mapbox-gl-draw';



@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;
  draw: MapboxDraw;
  private polygonCurrent: string;
  public isTruSo:boolean;

  constructor(
    private Dialog: MatDialog,
    public ActivatedRoute: ActivatedRoute,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucService: ToChucService,
    public ToChucVungNuoiService: ToChucVungNuoiService,
    public ToChucToaDoService: ToChucToaDoService,

  ) 
  { 
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      }
    });
  }


  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.ActivatedRoute.paramMap.subscribe((params) => {
      this.ToChucService.BaseParameter.ID = Number(params.get('ID')!);
      if (this.ToChucVungNuoiService.FormData.ID > 0) {
        this.GetToChucVungNuoiByID();
      }
      this.MapLoad(1);
    });
  }
  ngOnDestroy() {
    this.map?.remove();
  }

  GetToChucVungNuoiByID() {
    this.ToChucVungNuoiService.BaseParameter.ID = this.ToChucVungNuoiService.FormData.ID;
    this.ToChucVungNuoiService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVungNuoiService.FormData = res as ToChucVungNuoi;
        this.ToChucVungNuoiService.IsShowLoading = false;
      },
      err => {
        this.ToChucVungNuoiService.IsShowLoading = false;
      }
    );

  }

  Save() {
    //let polygon = JSON.parse(localStorage.getItem(this.polygonCurrent));
    let polygon = JSON.parse(this.polygonCurrent || '[]');
    if (polygon) {
      if (polygon.length > 0) {
        this.ToChucService.IsShowLoading = true;
        this.ToChucVungNuoiService.FormData.ParentID = this.ToChucService.FormData.ID;
        this.ToChucVungNuoiService.FormData.ParentName = this.ToChucService.FormData.Name;
       // this.ToChucVungNuoiService.SaveAsync().subscribe(res => {
          //this.ToChucVungNuoiService.FormData = res as ToChucVungNuoi;
          // Nếu Active = true, chỉ lưu tọa độ đầu tiên
          let isFirstPoint = true;
          if (this.isTruSo) {
            polygon.forEach((point) => {
              const formData = { ...this.ToChucToaDoService.FormData }; // Tạo bản sao mới cho mỗi điểm
              formData.ID = environment.InitializationNumber;
              formData.ParentID = this.ToChucService.FormData.ID;
              formData.ParentName = this.ToChucService.FormData.Name;
              formData.Code = this.ToChucService.FormData.Code;
              formData.KinhDo = point[0];
              formData.ViDo = point[1];
              if (isFirstPoint) {
                formData.Active = this.isTruSo; 
                isFirstPoint = false; 
              }
              this.ToChucToaDoService.List.push(formData);
            });
          } else {
            // Nếu Active = false, tất cả các điểm đều có Active = false
            polygon.forEach((point) => {
              const formData = { ...this.ToChucToaDoService.FormData }; // Tạo bản sao mới cho mỗi điểm
              formData.ID = environment.InitializationNumber;
              formData.ToChucVungNuoiID = this.ToChucVungNuoiService.FormData.ID;
              formData.ToChucVungNuoiName = this.ToChucVungNuoiService.FormData.Name;
              formData.Code = this.ToChucVungNuoiService.FormData.Code;
              formData.ParentID = this.ToChucService.FormData.ID;
              formData.ParentName = this.ToChucService.FormData.Name;
              formData.KinhDo = point[0];
              formData.ViDo = point[1];
              formData.Active = false; // Đảm bảo tất cả các điểm có Active = false
          
              this.ToChucToaDoService.List.push(formData);
            });
          }          
          // Lưu danh sách tọa độ
          this.ToChucToaDoService.SaveListAsync(this.ToChucToaDoService.List).subscribe(
            () => {
              this.NotificationService.warn(environment.SaveSuccess);
            },
            () => {
              this.NotificationService.warn(environment.SaveNotSuccess);
            },
            () => {
              this.ToChucService.IsShowLoading = false;
            }
          );
       // });
      } else {
        this.NotificationService.warn(environment.ToChucToaDoSaveThongBao);
      }
    } else {
      this.NotificationService.warn(environment.ToChucToaDoSaveThongBao);
    }
  }


  MapInitialization(ID, longitude, latitude) {
    // Khởi tạo MapboxDraw
    // const draw = new MapboxDraw({
    //   displayControlsDefault: false,
    //   controls: {
    //     polygon: true,
    //     trash: true
    //   }
    // });
    // Đảm bảo tọa độ hợp lệ
    let zoom = environment.MapZoom;
    if ((latitude > 90) || (latitude === 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }

    // Khởi tạo bản đồ với style tùy theo ID
    const styleURL = ID === 0
      ? 'https://api.maptiler.com/maps/streets/style.json?key=' + environment.MaptilerAPIKey
      : 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey;

    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: styleURL,
      center: [longitude, latitude],
      zoom: zoom,
    });

    // Thêm các control
    this.map.addControl(new maplibregl.NavigationControl({
      visualizePitch: true,
      showZoom: true,
      showCompass: true,
    }));
    this.map.addControl(new maplibregl.FullscreenControl());
    this.map.addControl(this.draw);

    // Sự kiện liên quan đến Draw
    this.map.on('draw.create', (e) => this.UpdateArea.bind(e));
    this.map.on('draw.delete', (e) => this.UpdateArea.bind(e));
    this.map.on('draw.update', (e) => this.UpdateArea.bind(e));
    this.map.on('draw.modechange', (e) => this.onModeChange(e));

    // Xử lý sự kiện khi bản đồ đã load
    this.map.on('load', () => {
      this.addImageLayers();
    });
  }

  onModeChange(e: any) {
    console.log('Mode changed to:', e.mode);
  
    if (e.mode === 'simple_select') {
      if (this.draw) {
        const features = this.draw.getAll().features;
        if (features.length > 0) {
          this.UpdateArea({ features });
        }
      } else {
        console.error('Draw control is not initialized.');
      }
    }
  }

  // Tách riêng phần thêm layer ảnh để code rõ ràng hơn
  addImageLayers() {
    this.map.addSource('HoangSa', {
      type: 'image',
      url: environment.DomainURL + 'assets/image/HoangSa01.png',
      coordinates: [
        [111.09665858054495, 17.432475898867523],
        [113.11720985517763, 17.38420482529338],
        [112.79285037220984, 15.643938718432054],
        [110.88537855035554, 15.672592116966598],
      ]
    });

    this.map.addLayer({
      id: 'HoangSa',
      source: 'HoangSa',
      type: 'raster',
      paint: { 'raster-opacity': 1 }
    });

    this.map.addSource('TruongSa', {
      type: 'image',
      url: environment.DomainURL + 'assets/image/TruongSa01.png',
      coordinates: [
        [112.32373278444106, 12.236103169381323],
        [117.4620551483049, 11.606334626304161],
        [115.59654957671216, 7.357025445897818],
        [110.62186805246108, 7.811210355974268],
      ]
    });

    this.map.addLayer({
      id: 'TruongSa',
      source: 'TruongSa',
      type: 'raster',
      paint: { 'raster-opacity': 1 }
    });
  }

  UpdateArea(e: any) {
    // Xóa dữ liệu polygon hiện tại khỏi localStorage
    localStorage.removeItem(environment.Polygon);
  
    const data = e.features[0]; // Lấy feature đầu tiên
    if (!data || data.geometry.type !== 'Polygon') {
      console.warn('No valid polygon found.');
      return;
    }
  
    const coordinates = data.geometry.coordinates[0]; // Lấy tọa độ của polygon
    console.log('Polygon Coordinates:', coordinates);
  
    // Cập nhật biến polygonCurrent và lưu vào localStorage
    this.polygonCurrent = JSON.stringify(coordinates);
    localStorage.setItem(environment.Polygon, this.polygonCurrent);
  
    // Chuyển về chế độ 'simple_select' để kết thúc việc vẽ
    this.draw.changeMode('simple_select');
  }  
  
  MapLoad(ID: number) {
    debugger
    this.ToChucService.IsShowLoading = true;
    this.GetToChucVungNuoiByID();
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = (res as ToChuc);
        if (this.ToChucService.FormData) {
          this.ToChucToaDoService.BaseParameter.ToChucVungNuoiID = this.ToChucVungNuoiService.FormData.ID;
          this.ToChucToaDoService.GetByToChucVungNuoiIDToListAsync().subscribe(
            res => {
              this.ToChucToaDoService.List = (res as ToChucToaDo[]);
              if (this.ToChucToaDoService.List) {
                let longitude = environment.Longitude;
                let latitude = environment.Latitude;
                if (this.ToChucToaDoService.List.length > 0) {
                  let ListToChucToaDoActive = this.ToChucToaDoService.List.filter((item: any) => item.Active == true);
                  if (ListToChucToaDoActive.length > 0) {
                  }
                  else {
                    ListToChucToaDoActive = [];
                    ListToChucToaDoActive.push(this.ToChucToaDoService.List[0]);
                  }

                  if (ListToChucToaDoActive.length > 0) {
                    longitude = this.DownloadService.GetKinhDo(Number(ListToChucToaDoActive[0].KinhDo));
                    latitude = this.DownloadService.GetViDo(Number(ListToChucToaDoActive[0].ViDo));
                  }
                  this.MapInitialization(ID, longitude, latitude);
                  var el = document.createElement('div');
                  el.style.backgroundImage = "url(assets/image/Icon.png)";
                  el.style.width = '30px';
                  el.style.height = '42px';

                  let popup = new maplibregl.Popup({ offset: 25 }).setHTML(this.getPopupHTML())
                  .setMaxWidth("400px");
                  let marker = new maplibregl.Marker({ element: el })
                    .setLngLat([longitude, latitude])
                    .addTo(this.map);


                  let ListToChucToaDoNotActive = this.ToChucToaDoService.List.filter((item: any) => item.Active == false);
                  if (ListToChucToaDoNotActive.length > 0) {
                    let listToaDoPolygon = [];
                    let listPolygon = [];
                    for (let j = 0; j < ListToChucToaDoNotActive.length; j++) {
                      let longitudeSub1 = this.DownloadService.GetKinhDo(Number(ListToChucToaDoNotActive[j].KinhDo));
                      let latitudeSub1 = this.DownloadService.GetViDo(Number(ListToChucToaDoNotActive[j].ViDo));
                      listToaDoPolygon.push([longitudeSub1, latitudeSub1]);
                    }
                    listPolygon.push(listToaDoPolygon);
                    let IDDate = new Date().toISOString();
                    let layerID = 'Layer_' + this.ToChucVungNuoiService.FormData.ID;
                    let sourceID = 'Source_' + this.ToChucVungNuoiService.FormData.ID;
                    this.map.on('load', () => {
                      this.map.addSource(sourceID, {
                        'type': 'geojson',
                        'data': {
                          'type': 'Feature',
                          'properties': {
                            "name": this.ToChucVungNuoiService.FormData.Name,
                            "address": this.ToChucService.FormData.DiaChi,
                          },
                          'geometry': {
                            'type': 'Polygon',
                            'coordinates': listPolygon,
                          }
                        }
                      });
                      let color = this.DownloadService.GetRandomColor(ListToChucToaDoNotActive.length);
                      this.map.addLayer({
                        'id': layerID,
                        'type': 'fill',
                        'source': sourceID,
                        'paint': {
                          'fill-color': color,
                          'fill-opacity': 0.5,
                          'fill-outline-color': color,
                        }
                      });
                    });
                    this.map.on('click', layerID, (e) => {
                      new maplibregl.Popup({ offset: 25 })
                        .setLngLat(e.lngLat)
                        .setHTML(this.getPopupHTML())
                        .setMaxWidth('600px')
                        .addTo(this.map);
                    });
                  }


                }
                else {
                  if (this.ToChucService.FormData.KinhDo && this.ToChucService.FormData.ViDo){
                    longitude = Number.parseFloat(this.ToChucService.FormData.KinhDo);
                    latitude = Number.parseFloat(this.ToChucService.FormData.ViDo);
                  }                
                  this.MapInitialization(ID, longitude, latitude);
                  var el = document.createElement('div');
                  el.style.backgroundImage = "url(assets/image/Icon.png)";
                  el.style.width = '30px';
                  el.style.height = '42px';

                  let marker = new maplibregl.Marker({ element: el })
                    .setLngLat([longitude, latitude])
                    .addTo(this.map);
                }
              }
            },
            err => {
            },
            () => {
            }
          );

        }
      },
      err => {

      },
      () => {
        this.ToChucService.IsShowLoading = false;
      }
    );

  }

  getPopupHTML() {
    let ToChuc = this.ToChucVungNuoiService.FormData; 
    let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
    html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
    html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
    html += "</div>";
    html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

    html += "<table style='width: 100%; border-spacing: 0;'>";
    html += "<tr>";
    html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
    // Combine Loại Hình and Trạng Thái Vùng Nuôi in the same row
    html += "<div style='padding: 2px; display: flex; justify-content: space-between; align-items: center;'>";
    html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
    html += "<div style='padding: 2px;'><b>Loại nuôi: </b>" + (this.ToChucVungNuoiService.FormData.DanhMucGiongName ? this.ToChucVungNuoiService.FormData.DanhMucGiongName : 'Dữ liệu đang cập nhật') + "</div>";
    html += "</td>";
    html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
    // Thêm các chi tiết bổ sung nếu cần cho bên phải
    html += "</td>";
    html += "</tr>";
    html += "</table>";
    html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
    html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
    html += "</div>";
    html += "</table>";

    html += "</div>";
    return html;
  }
}
